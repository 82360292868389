import React from "react"

const Portfolios = () => {
  return (
    <>
      <section className='culture'>
        <div className='container paddingTB'>
          <h1>Portfolios Sections</h1>
        </div>
      </section>
    </>
  )
}

export default Portfolios
