import React from 'react';
import './footer.css';

const Footer = () => {
    return (
        <>
            <div className="legal  ">
                <div className="container flexSB">
                    <p>Loot</p>
                </div>
            </div>
        </>
    );
};

export default Footer;
